import React, { FC, useState } from "react";
import {
    PartialRevisionData,
    PartialRevisionVariables,
    Revision,
    RevisionConfig,
    RevisionConfigInput,
    RevisionConfigVariables,
    VariationSettings
} from "../../models/types";
import {
    Button,
    Card,
    Col,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import { NameTemplatePreview } from "../views/settings/NameTemplatePreview";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
    PARTIAL_UPDATE_REVISION,
    UPDATE_REVISION_CONFIG
} from "../../graphql/mutations";
import { updateRevisionPartially } from "../../common/Helpers";
import { GET_REVISION_CONFIG } from "../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import styles from "./VariationSettingsItem.module.css";

interface VariationSettingsProps {
    defaultValue: VariationSettings;
    revision: Revision;
    developerMode: boolean;
}

export const VariationSettingsItem: FC<VariationSettingsProps> = ({
    defaultValue,
    revision,
    developerMode
}) => {
    const client = useApolloClient();
    const [showBasicInfo, updateShowBasicInfo] = useState(false);
    const [showPipelineInfo, updateShowPipelineInfo] = useState(false);
    const [showLegacyInfo, updateShowLegacyInfo] = useState(false);
    const [showMiscInfo, updateShowMiscInfo] = useState(false);
    const [currentNameTemplate, updateCurrentNameTemplate] = useState(
        defaultValue.namingScheme
    );
    const [variationSettings, setVariationSettings] = useState(defaultValue);

    const { data: { revisionConfig } = {} } = useQuery<
        {
            revisionConfig: RevisionConfig;
        },
        RevisionConfigVariables
    >(GET_REVISION_CONFIG, {
        variables: { revisionId: Number(revision.id) }
    });

    const [partialUpdateRevision] = useMutation<
        PartialRevisionData,
        PartialRevisionVariables
    >(PARTIAL_UPDATE_REVISION);
    const [updateRevisionConfig] = useMutation(UPDATE_REVISION_CONFIG);

    const updateSettings = async (settings: VariationSettings) => {
        if (revision) {
            const newBuildData = {
                nopixi: settings.nopixi,
                debug: settings.debug,
                generateReport: settings.generateReport,
                generateStats: settings.generateStats,
                nameTemplate: settings.namingScheme,
                pathTemplate: settings.pathScheme,
                forceUppercasePath: settings.forceUppercasePath,
                useDurationNames: settings.useDurationNames,
                useLegacyLocalizations: settings.useLegacyLocalizations,
                useLegacyNetworks: settings.useLegacyNetworks,
                gzip: settings.gzip,
                useAditor: settings.useAditor,
                useMutator: settings.useMutator
            };
            await updateRevisionPartially(
                client,
                revision,
                partialUpdateRevision,
                newBuildData,
                undefined
            );
        }
    };

    const updateNameTemplate = async (newNameTemplate: string) => {
        if (variationSettings.namingScheme !== newNameTemplate) {
            const newState = {
                ...variationSettings,
                namingScheme: newNameTemplate
            };
            await updateSettings(newState);
            setVariationSettings(newState);
        }
    };

    const updatePathTemplate = async (newPathTemplate: string) => {
        if (variationSettings.pathScheme !== newPathTemplate) {
            const newState = {
                ...variationSettings,
                pathScheme: newPathTemplate
            };
            await updateSettings(newState);
            setVariationSettings(newState);
        }
    };

    const updateRevisionConfigSettings = async (
        useTemplatorInPipeline: boolean,
        allowTemplatorConfigModification: boolean
    ) => {
        const input: RevisionConfigInput = {
            revisionId: Number(revision.id),
            useTemplatorInPipeline: useTemplatorInPipeline,
            allowTemplatorConfigModification: allowTemplatorConfigModification
        };
        console.log("[DEBUG] input ", input);
        await updateRevisionConfig({
            variables: {
                input: input
            }
        });
    };

    const enableConfigurator = async () => {
        const input = {
            revisionId: Number(revision.id),
            configurator: {}
        };
        await updateRevisionConfig({
            variables: {
                input: input
            }
        });
    };

    const disableConfigurator = async () => {
        const input = {
            revisionId: Number(revision.id),
            configurator: false
        };
        await updateRevisionConfig({
            variables: {
                input: input
            }
        });
    };

    return (
        <>
            <Col className="col-12 d-flex justify-content-center">
                <Card
                    className={cx(
                        "p-0 mt-4",
                        developerMode
                            ? styles.settingsItem
                            : styles.settingsItemClient
                    )}
                >
                    <Card.Header className="d-flex text-inverse-50">
                        {developerMode ? (
                            <h5>Basic Settings</h5>
                        ) : (
                            <h5>Name Settings</h5>
                        )}

                        <div
                            className={cx("float-end ms-auto mouseHover", {
                                "text-inverse-50": showBasicInfo,
                                "text-inverse-25": !showBasicInfo
                            })}
                            onClick={() => updateShowBasicInfo(!showBasicInfo)}
                        >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                title="Toggle help and additional info"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body className="pb-1">
                        <div className="mb-3">
                            <InputGroup>
                                <InputGroup.Text>Name Template</InputGroup.Text>
                                <FormControl
                                    defaultValue={
                                        variationSettings.namingScheme
                                    }
                                    onChange={(event: any) => {
                                        updateCurrentNameTemplate(
                                            event.target.value
                                        );
                                    }}
                                    onBlur={async (event: any) => {
                                        updateNameTemplate(event.target.value);
                                    }}
                                />
                            </InputGroup>
                            <Collapse
                                in={showBasicInfo}
                                className="col-12 m-0 p-0"
                            >
                                <div>
                                    <Card.Footer className="mt-1 mb-1 no-border text-inverse-50 bread-text">
                                        <span>
                                            Build file names will be generated
                                            based on this customizable template
                                            tool. The template parameters can be
                                            modified according to the rules
                                            outlined below. See the preview
                                            feature to check how your file name
                                            will turn out.
                                        </span>
                                    </Card.Footer>
                                </div>
                            </Collapse>
                            <Collapse
                                in={showBasicInfo}
                                className="col-12 m-0 p-0"
                            >
                                <div>
                                    <Card.Footer className="mt-1 mb-1 text-inverse-50 bread-text">
                                        <h6 className="text-inverse">
                                            Name Scheme Support
                                        </h6>
                                        <ul>
                                            <li>
                                                {"#{parameter}"} - parameter
                                                will not be modified
                                            </li>
                                            <li>
                                                {"#{Parameter}"} - capitalizes
                                                the first letter of the
                                                parameter
                                            </li>
                                            <li>
                                                {"#{PARAMETER}"} - converts the
                                                entire parameter to uppercase
                                            </li>
                                        </ul>
                                    </Card.Footer>
                                </div>
                            </Collapse>
                            <Collapse
                                in={showBasicInfo}
                                className="col-12 m-0 p-0"
                            >
                                <div>
                                    <Card.Footer className="mt-1 mb-0 no-border text-inverse-50 bread-text">
                                        <h6 className="text-inverse">
                                            Parameter Support
                                        </h6>
                                        <div>
                                            <ul>
                                                <li>
                                                    {"#{project}"} =
                                                    Capitalization, UPPERCASE
                                                </li>
                                                <li>
                                                    {"#{network}"} =
                                                    Capitalization, UPPERCASE
                                                </li>
                                                <li>
                                                    {"#{build}"} =
                                                    Capitalization, UPPERCASE
                                                </li>
                                                <li>
                                                    {"#{variation}"} =
                                                    Capitalization, UPPERCASE
                                                </li>
                                                <li>
                                                    {"#{duration}"} = UPPERCASE
                                                </li>
                                                <li>{"#{size}"} = None</li>
                                            </ul>
                                        </div>
                                    </Card.Footer>
                                </div>
                            </Collapse>
                            <Collapse
                                in={showBasicInfo}
                                className="col-12 m-0 p-0"
                            >
                                <div>
                                    <Card.Footer className="mt-1 mb-0 no-border text-inverse-50 bread-text">
                                        <h6 className="text-inverse">
                                            Additional Notes
                                        </h6>
                                        <div>
                                            <ul>
                                                <li>
                                                    The {"#{variation}"}{" "}
                                                    parameter requires its own
                                                    separator. Add a separator
                                                    before "variation" string
                                                    inside the curly brackets,
                                                    e.g., {"#{_variation}"}
                                                </li>
                                                <li>
                                                    Both capitalization and
                                                    uppercase modifiers are
                                                    applied to every instance of
                                                    the variation. For example,{" "}
                                                    {"#{-Variation}"} generates
                                                    "Variation1-Variation2"
                                                </li>
                                                <li>
                                                    Network Capitalization and
                                                    UPPERCASE modifiers are not
                                                    applied to networks using
                                                    name overrides
                                                </li>
                                            </ul>
                                        </div>
                                    </Card.Footer>
                                </div>
                            </Collapse>
                        </div>
                        <NameTemplatePreview
                            namingScheme={currentNameTemplate}
                        />
                        {developerMode ? (
                            <>
                                <div className="mb-3">
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Path Template
                                        </InputGroup.Text>
                                        <FormControl
                                            defaultValue={
                                                variationSettings.pathScheme
                                            }
                                            onBlur={async (event: any) => {
                                                updatePathTemplate(
                                                    event.target.value
                                                );
                                            }}
                                        />
                                        {!variationSettings.pathScheme ? (
                                            <small className="text-danger col-12 ps-0 pt-2">
                                                Missing path template!
                                            </small>
                                        ) : null}
                                    </InputGroup>
                                    <Collapse
                                        in={showBasicInfo}
                                        className="col-12 m-0 p-0"
                                    >
                                        <div>
                                            <Card.Footer className="mt-1 no-border bread-text">
                                                <h6 className="text-inverse">
                                                    Path Template Support
                                                </h6>
                                                <div className="text-inverse-50">
                                                    <ul>
                                                        <li>
                                                            Separate folders
                                                            with forward slash
                                                            (/)
                                                        </li>
                                                        <li>
                                                            Following variables
                                                            can be used in the
                                                            folder structure
                                                        </li>
                                                        <ul>
                                                            <li>network</li>
                                                            <li>
                                                                localization
                                                            </li>
                                                            <li>
                                                                customFolder
                                                            </li>
                                                            <li>size</li>
                                                        </ul>
                                                        <li>
                                                            Example:{" "}
                                                            <i>
                                                                network/customFolder/size
                                                            </i>
                                                        </li>
                                                        <li>
                                                            Example (with legacy
                                                            localizations):{" "}
                                                            <i>
                                                                network/customFolder/localization/size
                                                            </i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Card.Footer>
                                        </div>
                                    </Collapse>
                                </div>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Force Uppercase
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        aria-label="Force Uppercase"
                                        checked={
                                            variationSettings.forceUppercasePath
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                forceUppercasePath:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Duration Names
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        aria-label="Use Duration Names"
                                        checked={
                                            variationSettings.useDurationNames
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                useDurationNames:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Generate Reports
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        aria-label="Generate Reports"
                                        checked={true}
                                        disabled
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                generateReport:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Generate Stats
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Generate Webpack Stats Data"
                                        aria-label="Generate Webpack Stats Data"
                                        checked={
                                            variationSettings.generateStats
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                generateStats:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-2 pt-0 mb-2 no-border text-inverse-50 bread-text">
                                            <div>
                                                <span>
                                                    Analytics are currently
                                                    supported only on select
                                                    networks.
                                                </span>
                                                <br />
                                                <span>
                                                    Currently supported
                                                    networks:{" "}
                                                    <b>Vungle, Unity</b>
                                                </span>
                                            </div>
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3 settings-check-box">
                                    <InputGroup.Text>Gzip</InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Enable Gzipped Builds"
                                        aria-label="Enable Gzipped Builds"
                                        checked={
                                            variationSettings.gzip || false
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                gzip: event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-2 pt-0 mb-2 no-border text-inverse-50 bread-text">
                                            Enables bundle.js gzipping for
                                            supported networks.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Debug</InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Keep console logs"
                                        aria-label="Keep console logs"
                                        checked={variationSettings.debug}
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                debug: event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-1 no-border text-inverse-50 bread-text">
                                            Forces debug settings for bundling.
                                            Results depend on project specific
                                            configurations, but always enables
                                            logging to console.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="mb-3 border border-danger text-danger bread-text">
                                            Do NOT enable <i>Debug</i> for
                                            production builds! Only for testing!
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Aditor
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Use Aditor"
                                        aria-label="Use Aditor"
                                        checked={
                                            variationSettings.useAditor || false
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                useAditor: event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-2 pt-0 mb-2 no-border text-inverse-50 bread-text">
                                            Enables Aditor view in platform and
                                            allows build pipeline to load scenes
                                            in builds.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Mutator
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Enable Mutator/Customator view"
                                        aria-label="Use Mutator"
                                        checked={
                                            variationSettings.useMutator ||
                                            false
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                useMutator: event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showBasicInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-2 no-border text-inverse-50 bread-text">
                                            Enables Mutator view in Platform and
                                            allows build pipeline to load
                                            assetBundles in builds. Feature is
                                            called{" "}
                                            <i className="text-inverse">
                                                Customator
                                            </i>{" "}
                                            in non-developer mode UI!
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                            </>
                        ) : null}
                    </Card.Body>
                </Card>
            </Col>
            {developerMode && revisionConfig ? (
                <>
                    <Col className="col-12 d-flex justify-content-center">
                        <Card className={cx("p-0", styles.settingsItem)}>
                            <Card.Header className="d-flex text-inverse-50">
                                <h5>Build Pipeline</h5>
                                <div
                                    className={cx(
                                        "float-end ms-auto mouseHover",
                                        {
                                            "text-inverse-50": showPipelineInfo,
                                            "text-inverse-25": !showPipelineInfo
                                        }
                                    )}
                                    onClick={() =>
                                        updateShowPipelineInfo(
                                            !showPipelineInfo
                                        )
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        title="Toggle help and additional info"
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body className="pb-1">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Templator in Build Pipeline
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Use Templator in Build Pipeline"
                                        aria-label="Use Templator in Build Pipeline"
                                        checked={
                                            revisionConfig.useTemplatorInPipeline
                                        }
                                        onChange={(event: any) => {
                                            updateRevisionConfigSettings(
                                                event.target.checked,
                                                revisionConfig.allowTemplatorConfigModification
                                            );
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showPipelineInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-3 no-border text-inverse-50 bread-text">
                                            Enable for projects, which require
                                            code to be generated before a
                                            network build.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Allow Templator Config to be modified in
                                        Platform
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        aria-label="Allow Templator Config to be
                                        modified in Platform"
                                        checked={
                                            revisionConfig.allowTemplatorConfigModification
                                        }
                                        onChange={(event: any) => {
                                            updateRevisionConfigSettings(
                                                revisionConfig.useTemplatorInPipeline,
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showPipelineInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="border border-info text-info bread-text">
                                            This setting overrides Templator
                                            config from Git if enabled!
                                        </Card.Footer>
                                        <Card.Footer className="mt-2 mb-2 border border-danger text-danger bread-text">
                                            <span>
                                                This setting is ignored if{" "}
                                                <i>Use Templator</i> in Build
                                                Pipeline is not enabled!
                                            </span>
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 d-flex justify-content-center">
                        <Card className={cx("p-0", styles.settingsItem)}>
                            <Card.Header className="d-flex text-inverse-50">
                                <h5>Legacy Settings</h5>
                                <div
                                    className={cx(
                                        "float-end ms-auto mouseHover",
                                        {
                                            "text-inverse-50": showLegacyInfo,
                                            "text-inverse-25": !showLegacyInfo
                                        }
                                    )}
                                    onClick={() =>
                                        updateShowLegacyInfo(!showLegacyInfo)
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        title="Toggle help and additional info"
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body className="pb-1">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Legacy Localizations
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        aria-label="Use Legacy Localizations"
                                        checked={
                                            variationSettings.useLegacyLocalizations
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                useLegacyLocalizations:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse in={showLegacyInfo}>
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-3 no-border text-inverse-50 bread-text">
                                            Enable if project uses Sets for
                                            Localizations.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>No Pixi</InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Keep console logs"
                                        aria-label="Keep console logs"
                                        checked={variationSettings.nopixi}
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                nopixi: event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse
                                    in={showLegacyInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-1 no-border text-inverse-50 bread-text">
                                            Enable if project is based on Pixi
                                            v4/v5 and not yet migrated to
                                            buildTools v3.
                                        </Card.Footer>
                                        <Card.Footer className="mt-1 mb-3 border border-info text-info bread-text">
                                            Deprecated in buildTools v3!
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        Use Legacy Networks
                                    </InputGroup.Text>
                                    <InputGroup.Checkbox
                                        title="Keep console logs"
                                        aria-label="Keep console logs"
                                        checked={
                                            variationSettings.useLegacyNetworks
                                        }
                                        onChange={(event: any) => {
                                            const newState = {
                                                ...variationSettings,
                                                useLegacyNetworks:
                                                    event.target.checked
                                            };
                                            setVariationSettings(newState);
                                            updateSettings(newState);
                                        }}
                                    />
                                </InputGroup>
                                <Collapse in={showLegacyInfo}>
                                    <div>
                                        <Card.Footer className="px-1 pb-1 pt-0 mb-1 no-border text-inverse-50 bread-text">
                                            Enable if project is based on Pixi
                                            v4/v5 and uses buildTools v3.
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                            </Card.Body>
                        </Card>
                    </Col>
                    {!revisionConfig?.configurator ? (
                        <Col className="col-12 d-flex justify-content-center">
                            <Card className={cx("p-0", styles.settingsItem)}>
                                <Card.Header className="d-flex text-inverse-50">
                                    <h5>Other Settings</h5>
                                    <div
                                        className={cx(
                                            "float-end ms-auto mouseHover",
                                            {
                                                "text-inverse-50": showMiscInfo,
                                                "text-inverse-25": !showMiscInfo
                                            }
                                        )}
                                        onClick={() =>
                                            updateShowMiscInfo(!showMiscInfo)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            title="Toggle help and additional info"
                                        />
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Button onClick={enableConfigurator}>
                                        Enable Configurator
                                    </Button>
                                    <Collapse in={showMiscInfo}>
                                        <div>
                                            <Card.Footer className="mt-1 p-1 no-border text-inverse-50 bread-text">
                                                These are miscellaneous
                                                settings.
                                            </Card.Footer>
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : (
                        <Col className="col-12 d-flex justify-content-center">
                            <Card
                                className={cx("p-0 mb-5", styles.settingsItem)}
                            >
                                <Card.Header className="d-flex text-inverse-50">
                                    <h5>Other Settings</h5>
                                    <div
                                        className={cx(
                                            "float-end ms-auto mouseHover",
                                            {
                                                "text-inverse-50": showMiscInfo,
                                                "text-inverse-25": !showMiscInfo
                                            }
                                        )}
                                        onClick={() =>
                                            updateShowMiscInfo(!showMiscInfo)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            title="Toggle help and additional info"
                                        />
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Button onClick={disableConfigurator}>
                                        Remove Configurator Support
                                    </Button>
                                    <Collapse in={showMiscInfo}>
                                        <div>
                                            <Card.Footer className="mt-1 mb-1 p-1 no-border text-inverse-50 bread-text">
                                                These are miscellaneous
                                                settings.
                                            </Card.Footer>
                                        </div>
                                    </Collapse>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </>
            ) : null}
        </>
    );
};
