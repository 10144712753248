import React, { FC, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { DragAndDrop } from "../editable/DragAndDrop";
import { useApolloClient, useMutation } from "@apollo/client";
import {
    UPLOAD_LOCALIZATION,
    UPLOAD_NEW_LOCALIZATION
} from "../../graphql/mutations";
import "./Modals.scss";
import {
    Localization,
    Revision,
    RevisionConfig,
    Types,
    UploadLocalizationData,
    UploadLocalizationVariables
} from "../../models/types";
import { useLocalState } from "../../graphql/hooks";
import { addLocalizationAsync } from "../../common/Helpers";

interface Props {
    show: boolean;
    revision: Revision;
    title: string;
    localization: string | null;
    projectLocalizations: string[] | undefined;
    updateRevision: (newBuildData?: Types, operation?: string) => void;
    onClose: () => void;
}

export const LocalizationUploadModal: FC<Props> = ({
    title,
    revision,
    show,
    localization,
    projectLocalizations,
    updateRevision,
    onClose
}) => {
    const client = useApolloClient();
    const [info, updateInfo] = useState("");
    const [files, updateFiles] = useState<File[]>([]);
    const [fileNames, updateFileNames] = useState<string[]>([]);
    const [submitting, updateSubmitting] = useState(false);
    const [unMount, setMountingStatus] = useState(false);
    const { useDarkMode } = useLocalState();

    const [uploadLocalization] = useMutation<
        RevisionConfig,
        UploadLocalizationVariables
    >(UPLOAD_LOCALIZATION);

    const [uploadNewLocalization] = useMutation<
        UploadLocalizationData,
        UploadLocalizationVariables
    >(UPLOAD_NEW_LOCALIZATION);

    useEffect(() => {
        if (unMount) {
            onClose();
        }
    }, [unMount, onClose]);

    const updateLocalizations = (localizationData: Localization) => {
        if (revision) {
            updateRevision({ localizations: [localizationData] }, "new");
        }
    };

    const addFile = (newFiles: File[]) => {
        if (localization) {
            if (newFiles.length === 1) {
                updateFiles(newFiles);
                updateFileNames([newFiles[0].name]);
            } else {
                console.log("[DEBUG] Only one json can be uploaded");
            }
        } else {
            const currentFiles = files;
            currentFiles.push(...newFiles);
            updateFiles(currentFiles);
            const filenames = currentFiles.map(file => file.name);
            updateFileNames(filenames);
        }
    };

    const removeFile = (name: string) => {
        const currentFiles = files.filter(file => file.name !== name);
        updateFiles(currentFiles);
        const filenames = currentFiles.map(file => file.name);
        updateFileNames(filenames);
    };

    const submit = async () => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].type !== "application/json") {
                updateInfo("Unsupported file type!");
                setTimeout(() => {
                    updateInfo("");
                }, 2000);
                updateSubmitting(false);
                return;
            }
        }

        if (!localization) {
            try {
                for (let index = 0; index < files.length; index++) {
                    const jsonString = await files[index].text();
                    const result = await addLocalizationAsync(
                        client,
                        uploadNewLocalization,
                        revision,
                        files[index].name,
                        jsonString
                    );
                    console.log("[DEBUG] addLocalizationAsync result ", result);
                }
                setMountingStatus(true);
            } catch (error) {
                // @ts-ignore
                updateInfo(error.message);
                setTimeout(() => {
                    updateInfo("");
                }, 4000);
            }
        } else {
            if (files.length !== 1) {
                updateInfo("File count does not match!");
                setTimeout(() => {
                    updateInfo("");
                }, 2000);
                updateSubmitting(false);
                return;
            }
            try {
                if (files[0].name === "localizations.json") {
                    updateInfo(
                        "Invalid operation detected! Do not upload localizations.json as single localization!"
                    );
                    setTimeout(() => {
                        updateInfo("");
                    }, 4000);
                    updateSubmitting(false);
                    return;
                }
                const jsonString = await files[0].text();

                await uploadLocalization({
                    variables: {
                        revisionId: revision.id,
                        localization: localization,
                        localizationJson: jsonString
                    }
                });
                setMountingStatus(true);
            } catch (error) {
                // @ts-ignore
                updateInfo(error.message);
                setTimeout(() => {
                    updateInfo("");
                }, 4000);
            }
        }

        updateSubmitting(false);
    };

    const addProjectLocalization = async (localization: string) => {
        const fileName = localization.split("/").pop();
        if (fileName) {
            const name = fileName.replace(".json", "");
            updateLocalizations({
                localization: localization,
                include: true,
                id: 0, //getNewId(revision.buildData.localizations),
                name: name,
                default: false
            });

            updateSubmitting(false);
            setMountingStatus(true);
        }
    };

    const projectLocalizationElements =
        projectLocalizations !== undefined &&
        projectLocalizations !== null &&
        projectLocalizations.length > 0
            ? projectLocalizations
                  .map((localization: string) => {
                      if (localization === "") return undefined;
                      if (
                          !revision.buildData.localizations.some(
                              (item: Localization) =>
                                  item.localization === localization
                          )
                      ) {
                          const name = localization.split("/").pop();
                          return (
                              <Button
                                  key={localization}
                                  variant="success"
                                  onClick={() => {
                                      updateSubmitting(true);
                                      addProjectLocalization(localization);
                                  }}
                                  className="m-1"
                              >
                                  {name}
                              </Button>
                          );
                      } else {
                          return undefined;
                      }
                  })
                  .filter(item => item !== undefined)
            : null;

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {projectLocalizationElements &&
                projectLocalizationElements.length > 0 ? (
                    <div className="mb-4 px-3">
                        <h5>Enable previously uploaded localization</h5>
                        {projectLocalizationElements}
                    </div>
                ) : null}
                <DragAndDrop
                    instructions={
                        <span>
                            You can add a localization by{" "}
                            <span className="text-info">dragging it here</span>{" "}
                            or using the upload button. Only JSON files are
                            currently supported!
                        </span>
                    }
                    fileNames={fileNames}
                    addFiles={addFile}
                    removeFile={removeFile}
                />
                <Form.Control
                    className="mt-4"
                    type="file"
                    id="localization-file-upload"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.files) {
                            addFile(Array.from(event.target.files));
                        }
                    }}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column justify-content-center align-content-center">
                <>
                    <h5 className="text-center text-danger">{info}</h5>
                    <Button
                        disabled={submitting}
                        onClick={() => {
                            updateSubmitting(true);
                            submit();
                        }}
                    >
                        {submitting ? "Submitting..." : "Submit"}
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    );
};
