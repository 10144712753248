import {
    AnalyticsLoadingState,
    AnalyticsQuerySet,
    AnalyticsQueryTask,
    AnalyticsQueryType,
    AnalyticsStateData,
    AnalyticsTimestamps,
    BatchBuild,
    FunnelData,
    FunnelTimestamps,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../models/types";
import { ApolloClient } from "@apollo/client/core";
import { GET_LOCAL_ANALYTICS_STATE } from "../graphql/queries";
import { getLastAnalyticsUpdateDate } from "./Helpers";
import { csv, DSVRowArray } from "d3";
import { AnalyticsState } from "../models/common";

export const updateLoadingState = (
    client: ApolloClient<any>,
    loadingState: AnalyticsLoadingState
) => {
    const data = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (data) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...data.analyticsState,
                    analyticsLoadingState: loadingState
                }
            }
        });
    }
};

export const updateAthenaTask = (
    client: ApolloClient<any>,
    task: AnalyticsQueryTask | undefined
) => {
    const data = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (data) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...data.analyticsState,
                    analyticsTask: task
                }
            }
        });
    }
};

export const resetAnalyticsResults = (
    client: ApolloClient<any>,
    queryType: AnalyticsQueryType
) => {
    const data = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (data) {
        switch (queryType) {
            case AnalyticsQueryType.AllCtr: {
                client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE,
                    data: {
                        analyticsState: {
                            ...data.analyticsState,
                            analyticsTask: undefined,
                            allCtrAnalyticsResults: []
                        }
                    }
                });
                break;
            }
            case AnalyticsQueryType.Funnel: {
                client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
                    query: GET_LOCAL_ANALYTICS_STATE,
                    data: {
                        analyticsState: {
                            ...data.analyticsState,
                            analyticsTask: undefined,
                            funnelAnalyticsResults: []
                        }
                    }
                });
                break;
            }
            default:
                console.log(
                    "[DEBUG] Analytics mode for " +
                        queryType +
                        " not implemented"
                );
                break;
        }
    }
};

export const stepColor = (value: number, darkMode: boolean) => {
    const color = darkMode ? 180 : 240;
    const h = (1.0 - value) * color;
    return "hsl(" + h + ", 100%, 50%)";
};

export const pastelStepColor = (value: number, opacity: number) => {
    const remap = (a: number, b: number, value: number) => {
        return a + value * (b - a);
    };
    const initialValue = 1.0 - value;
    const remapped = remap(0.3, 0.7, initialValue);
    const color = 240;
    const h = remapped * color;
    return `hsla(${h}, 100%, 50%, ${opacity})`;
};

export const setupFunnel = (
    client: ApolloClient<any>,
    config: {
        products?: Product[];
        projects?: Project[];
        revisions?: Revision[];
        builds?: BatchBuild[];
        networkBuilds?: NetworkBuild[];
    }
) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    analyticsProducts: config.products || [],
                    analyticsProjects: config.projects || [],
                    analyticsRevisions: config.revisions || [],
                    analyticsBuilds: config.builds || [],
                    analyticsNetworkBuilds: config.networkBuilds || [],
                    funnelEvents: [
                        { eventName: "", eventData: [] },
                        { eventName: "", eventData: [] }
                    ],
                    analyticsTimestamps: getDefaultTimestamps()
                }
            }
        });
    }
};

export const clearFunnelSelection = (client: ApolloClient<any>) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    analyticsProducts: [],
                    analyticsProjects: [],
                    analyticsRevisions: [],
                    analyticsBuilds: [],
                    analyticsNetworkBuilds: [],
                    funnelEvents: [
                        { eventName: "", eventData: [] },
                        { eventName: "", eventData: [] }
                    ]
                }
            }
        });
    }
};

export const clearFunnelResults = (client: ApolloClient<any>) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    analyticsTask: undefined,
                    funnelAnalyticsResults: []
                }
            }
        });
    }
};
export const loadCsv = async (url: string): Promise<DSVRowArray> => {
    const result = await csv(url);
    return result;
};

export const updateFunnelItems = (
    client: ApolloClient<any>,
    funnelItems: FunnelData[]
) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    funnelEvents: funnelItems
                }
            }
        });
    }
};

export const hasFunnelSelection = (
    analyticsProducts: Product[],
    analyticsProjects: Project[],
    analyticsRevisions: Revision[],
    analyticsBuilds: BatchBuild[]
) => {
    if (
        analyticsProducts.length > 0 &&
        analyticsProducts.some(element => element.selected)
    ) {
        return true;
    }
    if (
        analyticsProjects.length > 0 &&
        analyticsProjects.some(element => element.selected)
    ) {
        return true;
    }
    if (
        analyticsRevisions.length > 0 &&
        analyticsRevisions.some(element => element.selected)
    ) {
        return true;
    }
    if (
        analyticsBuilds.length > 0 &&
        analyticsBuilds.some(element => element.selected)
    ) {
        return true;
    }
    // For now network builds are handled via report page only
    // if (state.analyticsNetworkBuilds.length > 0) {
    //     return true;
    // }
    return false;
};

export const hasFunnelSelectionForNetworkBuilds = (
    analyticsNetworkBuilds: NetworkBuild[]
) => {
    return (
        analyticsNetworkBuilds.length > 0 &&
        analyticsNetworkBuilds.some(element => element.selected)
    );
};

export const toggleFunnelSelectionMode = (
    client: ApolloClient<any>,
    mode: boolean
) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    analyticsFunnelSelectionMode: mode
                }
            }
        });
    }
};

export const updateAnalyticsTimestamps = (
    client: ApolloClient<any>,
    newTimestamp: AnalyticsTimestamps
) => {
    if (isNaN(newTimestamp.startTimestamp)) {
        newTimestamp.startTimestamp = -1;
    }
    if (isNaN(newTimestamp.endTimestamp)) {
        newTimestamp.endTimestamp = -1;
    }
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    analyticsTimestamps: newTimestamp
                }
            }
        });
    }
};

export const updateFunnelTimestamps = (
    client: ApolloClient<any>,
    newTimestamp: FunnelTimestamps
) => {
    if (isNaN(newTimestamp.startTimestamp)) {
        newTimestamp.startTimestamp = -1;
    }
    if (isNaN(newTimestamp.endTimestamp)) {
        newTimestamp.endTimestamp = -1;
    }
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...currentCache.analyticsState,
                    funnelTimestamps: newTimestamp
                }
            }
        });
    }
};

export const getDefaultTimestamps = (): AnalyticsTimestamps => {
    const endDate = getLastAnalyticsUpdateDate();
    const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
    return {
        startTimestamp: startDate.getTime() / 1000,
        endTimestamp: endDate.getTime() / 1000
    };
};

export const getDefaultFunnelTimestamps = (): FunnelTimestamps => {
    const endDate = getLastAnalyticsUpdateDate();
    const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
    return {
        startTimestamp: startDate.getTime() / 1000,
        endTimestamp: endDate.getTime() / 1000
    };
};

export const formatDateRange = (
    startTimestamp: number,
    endTimestamp: number
): string => {
    const startDate = new Date(startTimestamp * 1000).toLocaleDateString();
    const endDate = new Date(endTimestamp * 1000).toLocaleDateString();
    return `${startDate} – ${endDate}`;
};

export const getParentSelection = (
    analyticsState: AnalyticsState,
    parent: string,
    target: any
): Product | Project | Revision | BatchBuild | NetworkBuild | undefined => {
    if (parent === "product") {
        return analyticsState.analyticsProducts.find(
            element => element.id === target.id
        );
    } else if (parent === "project") {
        return analyticsState.analyticsProjects.find(
            element => element.id === target.id
        );
    } else if (parent === "revision") {
        return analyticsState.analyticsRevisions.find(
            element => element.id === target.id
        );
    } else if (parent === "build") {
        return analyticsState.analyticsBuilds.find(
            element => element.id === target.id
        );
    }
    return undefined;
};

export const getParentComparison = (
    analyticsState: AnalyticsState,
    parent: string,
    root: string,
    target: any
): boolean => {
    if (parent === "product") {
        const product = getParentSelection(analyticsState, parent, target);
        if (product) {
            return (product.selected && product?.comparison === root) || false;
        }
    } else if (parent === "project") {
        const project = getParentSelection(analyticsState, parent, target);
        if (project) {
            return (project.selected && project?.comparison === root) || false;
        }
    } else if (parent === "revision") {
        const revision = getParentSelection(analyticsState, parent, target);
        if (revision) {
            return (
                (revision.selected && revision?.comparison === root) || false
            );
        }
    } else if (parent === "build") {
        const build = getParentSelection(analyticsState, parent, target);
        if (build) {
            return (build.selected && build?.comparison === root) || false;
        }
    }
    return false;
};

const includesRoot = (
    analyticsState: AnalyticsState,
    root: string,
    item: any,
    parents: any[]
) => {
    let includes = item.comparison === root;
    if (!includes) {
        for (let i = 0; i < parents.length; i++) {
            if (
                getParentComparison(
                    analyticsState,
                    parents[i][0],
                    parents[i][1],
                    parents[i][2]
                )
            ) {
                includes = true;
                break;
            }
        }
    }
    return includes;
};

const generateQuerySet = (analyticsState: AnalyticsState, root: string) => {
    const selection = {
        products: analyticsState.analyticsProducts.flatMap(
            (product: Product) =>
                product.selected &&
                includesRoot(analyticsState, root, product, [])
                    ? Number(product.id)
                    : []
        ),
        projects: analyticsState.analyticsProjects.flatMap(
            (project: Project) =>
                project.selected &&
                includesRoot(analyticsState, root, project, [
                    ["product", root, project.product]
                ])
                    ? Number(project.id)
                    : []
        ),
        revisions: analyticsState.analyticsRevisions.flatMap(
            (revision: Revision) =>
                revision.selected &&
                includesRoot(analyticsState, root, revision, [
                    ["project", root, revision.project],
                    ["product", root, revision.project.product]
                ])
                    ? Number(revision.id)
                    : []
        ),
        builds: analyticsState.analyticsBuilds.flatMap((build: BatchBuild) =>
            build.selected &&
            includesRoot(analyticsState, root, build, [
                ["revision", root, build.revision],
                ["project", root, build.revision?.project],
                ["product", root, build.revision?.project?.product]
            ])
                ? Number(build.id)
                : []
        ),
        networkBuilds: analyticsState.analyticsNetworkBuilds.flatMap(
            (networkBuild: NetworkBuild) =>
                networkBuild.selected &&
                includesRoot(analyticsState, root, networkBuild, [
                    ["build", root, networkBuild.build],
                    ["revision", root, networkBuild.build?.revision],
                    ["project", root, networkBuild.build?.revision.project],
                    [
                        "product",
                        root,
                        networkBuild.build?.revision.project.product
                    ]
                ])
                    ? Number(networkBuild.id)
                    : []
        )
    };
    const exclusion = {
        products: analyticsState.analyticsProducts.flatMap(
            (product: Product) =>
                !product.selected &&
                includesRoot(analyticsState, root, product, [])
                    ? Number(product.id)
                    : []
        ),
        projects: analyticsState.analyticsProjects.flatMap(
            (project: Project) =>
                !project.selected &&
                includesRoot(analyticsState, root, project, [
                    ["product", root, project.product]
                ])
                    ? Number(project.id)
                    : []
        ),
        revisions: analyticsState.analyticsRevisions.flatMap(
            (revision: Revision) =>
                !revision.selected &&
                includesRoot(analyticsState, root, revision, [
                    ["project", root, revision.project],
                    ["product", root, revision.project.product]
                ])
                    ? Number(revision.id)
                    : []
        ),
        builds: analyticsState.analyticsBuilds.flatMap((build: BatchBuild) =>
            !build.selected &&
            includesRoot(analyticsState, root, build, [
                ["revision", root, build.revision],
                ["project", root, build.revision.project],
                ["product", root, build.revision.project.product]
            ])
                ? Number(build.id)
                : []
        ),
        networkBuilds: analyticsState.analyticsNetworkBuilds.flatMap(
            (networkBuild: NetworkBuild) =>
                !networkBuild.selected &&
                includesRoot(analyticsState, root, networkBuild, [
                    ["build", root, networkBuild.build],
                    ["revision", root, networkBuild.build?.revision],
                    ["project", root, networkBuild.build?.revision.project],
                    [
                        "product",
                        root,
                        networkBuild.build?.revision.project.product
                    ]
                ])
                    ? Number(networkBuild.id)
                    : []
        )
    };
    return { selection: selection, exclusion: exclusion };
};

const generateSelection = (analyticsState: AnalyticsState) => {
    const selection = {
        products: analyticsState.analyticsProducts.flatMap(
            (product: Product) => (product.selected ? Number(product.id) : [])
        ),
        projects: analyticsState.analyticsProjects.flatMap(
            (project: Project) => (project.selected ? Number(project.id) : [])
        ),
        revisions: analyticsState.analyticsRevisions.flatMap(
            (revision: Revision) =>
                revision.selected ? Number(revision.id) : []
        ),
        builds: analyticsState.analyticsBuilds.flatMap((build: BatchBuild) =>
            build.selected ? Number(build.id) : []
        ),
        networkBuilds: analyticsState.analyticsNetworkBuilds.flatMap(
            (networkBuild: NetworkBuild) =>
                networkBuild.selected ? Number(networkBuild.id) : []
        )
    };
    const exclusion = {
        products: analyticsState.analyticsProducts.flatMap(
            (product: Product) => (!product.selected ? Number(product.id) : [])
        ),
        projects: analyticsState.analyticsProjects.flatMap(
            (project: Project) => (!project.selected ? Number(project.id) : [])
        ),
        revisions: analyticsState.analyticsRevisions.flatMap(
            (revision: Revision) =>
                !revision.selected ? Number(revision.id) : []
        ),
        builds: analyticsState.analyticsBuilds.flatMap((build: BatchBuild) =>
            !build.selected ? Number(build.id) : []
        ),
        networkBuilds: analyticsState.analyticsNetworkBuilds.flatMap(
            (networkBuild: NetworkBuild) =>
                !networkBuild.selected ? Number(networkBuild.id) : []
        )
    };
    return { selection: selection, exclusion: exclusion };
};

const getRoots = (list: any) => {
    const roots: string[] = [];
    const elements = list.filter(
        (item: any) => item.selected && item?.comparison
    );
    if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].comparison !== undefined) {
                if (!roots.includes(elements[i].comparison)) {
                    roots.push(elements[i].comparison);
                }
            }
        }
    }
    return roots;
};

export const generateQuerySets = (analyticsState: AnalyticsState) => {
    const selectionQuerySets = [];
    const comparisonRoots = [];
    comparisonRoots.push(...getRoots(analyticsState.analyticsNetworkBuilds));
    comparisonRoots.push(...getRoots(analyticsState.analyticsBuilds));
    comparisonRoots.push(...getRoots(analyticsState.analyticsRevisions));
    comparisonRoots.push(...getRoots(analyticsState.analyticsProjects));
    comparisonRoots.push(...getRoots(analyticsState.analyticsProducts));
    if (comparisonRoots.length === 0) {
        return [generateSelection(analyticsState)];
    } else {
        for (let i = 0; i < comparisonRoots.length; i++) {
            const selectionData = generateQuerySet(
                analyticsState,
                comparisonRoots[i]
            );
            if (
                selectionData.selection.products.length === 0 &&
                selectionData.selection.projects.length === 0 &&
                selectionData.selection.revisions.length === 0 &&
                selectionData.selection.builds.length === 0 &&
                selectionData.selection.networkBuilds.length === 0
            ) {
                continue;
            }
            selectionQuerySets.push(selectionData);
        }
    }
    return selectionQuerySets;
};

export const hasValidQuery = (analyticsState: AnalyticsState) => {
    if (
        analyticsState.analyticsTimestamps.startTimestamp === -1 ||
        analyticsState.analyticsTimestamps.endTimestamp === -1
    ) {
        return false;
    }
    const querySets = generateQuerySets(analyticsState);
    let isValid = false;
    for (let i = 0; i < querySets.length; i++) {
        if (querySets[i].selection.products.length > 0) {
            isValid = true;
            break;
        }
        if (querySets[i].selection.projects.length > 0) {
            isValid = true;
            break;
        }
        if (querySets[i].selection.revisions.length > 0) {
            isValid = true;
            break;
        }
        if (querySets[i].selection.builds.length > 0) {
            isValid = true;
            break;
        }
        if (querySets[i].selection.networkBuilds.length > 0) {
            isValid = true;
            break;
        }
    }
    return isValid;
};

export const getSelectionById = (
    analyticsState: AnalyticsState,
    tier: string,
    id: number
): Product | Project | Revision | BatchBuild | NetworkBuild | undefined => {
    if (tier === "products") {
        return analyticsState.analyticsProducts.find(
            element => Number(element.id) === Number(id)
        );
    } else if (tier === "projects") {
        return analyticsState.analyticsProjects.find(
            element => Number(element.id) === Number(id)
        );
    } else if (tier === "revisions") {
        return analyticsState.analyticsRevisions.find(
            element => Number(element.id) === Number(id)
        );
    } else if (tier === "builds") {
        return analyticsState.analyticsBuilds.find(
            element => Number(element.id) === Number(id)
        );
    }
    return undefined;
};

export const getTree = (
    analyticsState: AnalyticsState,
    id: number,
    reference: string
) => {
    const querySets = generateQuerySets(analyticsState);
    let myTree: AnalyticsQuerySet | undefined = undefined;
    if (reference === "products") {
        for (let i = 0; i < querySets.length; i++) {
            if (querySets[i].selection.products.includes(id)) {
                myTree = querySets[i];
            }
        }
    } else if ("projects") {
        for (let i = 0; i < querySets.length; i++) {
            if (querySets[i].selection.projects.includes(id)) {
                myTree = querySets[i];
            }
        }
    }
    const keyList = [
        "networkBuilds",
        "builds",
        "revisions",
        "projects",
        "products"
    ];
    if (myTree !== undefined) {
        let found = false;
        let lastId = -1;
        for (const tier of keyList) {
            // @ts-ignore
            if (found && myTree.selection[tier].length === 0) {
                const element = getSelectionById(
                    analyticsState,
                    "revisions",
                    lastId
                );
                if (element !== undefined) {
                    // @ts-ignore
                    myTree.selection[tier].push(Number(element.project.id));
                }
            }
            // @ts-ignore
            if (!found && myTree.selection[tier].length > 0) {
                found = true;
            }
            // @ts-ignore
            if (myTree.selection[tier].length > 0) {
                // @ts-ignore
                lastId = myTree.selection[tier][0];
            }
        }
    }
    return myTree;
};

export const hasExtraSelection = (analyticsState: AnalyticsState) => {
    const checkList = (list: any[], selectionKey: string) => {
        for (let i = 0; i < list.length; i++) {
            if (!list[i].selected) {
                continue;
            }
            let isSelected = false;
            for (let j = 0; j < querySets.length; j++) {
                const id = Number(list[i].id);
                // @ts-ignore
                if (querySets[j].selection[selectionKey].includes(id)) {
                    isSelected = true;
                    break;
                }
            }
            if (!isSelected) {
                if (!hasOrphans) {
                    hasOrphans = true;
                }
                // @ts-ignore
                orphans[selectionKey].push(list[i].id);
            }
        }
    };
    const keyList = [
        "products",
        "projects",
        "revisions",
        "builds",
        "networkBuilds"
    ];
    const stateKeyList = [
        "analyticsProducts",
        "analyticsProjects",
        "analyticsRevisions",
        "analyticsBuilds",
        "analyticsNetworkBuilds"
    ];
    let hasOrphans = false;
    const orphans: {
        products: number[];
        projects: number[];
        revisions: number[];
        builds: number[];
        networkBuilds: number[];
    } = {
        products: [],
        projects: [],
        revisions: [],
        builds: [],
        networkBuilds: []
    };
    const querySets = generateQuerySets(analyticsState);
    for (let i = 0; i < keyList.length; i++) {
        // @ts-ignore
        checkList(analyticsState[stateKeyList[i]], keyList[i]);
    }
    return { hasOrphans, orphans };
};

export const updateAnalyticsState = (
    client: ApolloClient<any>,
    newState: AnalyticsState
) => {
    const currentCache = client.readQuery<AnalyticsStateData>({
        query: GET_LOCAL_ANALYTICS_STATE
    });
    if (currentCache && currentCache.analyticsState) {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: newState
            }
        });
    }
};
