import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import {
    AnalyticsQueryType,
    AnalyticsSchemaData,
    AnalyticsSchemaVariables,
    BatchBuild,
    FunnelData,
    NetworkBuild,
    Product,
    Project,
    Revision
} from "../../../models/types";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { EditableFunnelComponent } from "../../editable/analytics/EditableFunnelComponent";
import { GET_ANALYTICS_SCHEMA } from "../../../graphql/queries";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { updateFunnelItems } from "../../../common/AnalyticsHelpers";
import { AnalyticsQueryButtonWrapper } from "../../buttons/analytics/AnalyticsQueryButtonWrapper";
import cx from "classnames";

interface LastSelection {
    products: Product[];
    projects: Project[];
    revisions: Revision[];
    builds: BatchBuild[];
    networkBuilds: NetworkBuild[];
}

interface Props {
    apiVersion: string;
    queryType: AnalyticsQueryType;
}

export const FunnelElements: FC<Props> = ({ apiVersion, queryType }) => {
    const client = useApolloClient();
    const analyticsState = useLocalAnalyticsState();
    const {
        analyticsBuilds,
        analyticsNetworkBuilds,
        analyticsProducts,
        analyticsProjects,
        analyticsRevisions,
        funnelEvents
    } = useLocalAnalyticsState();
    const [currentSelection, setCurrentSelection] = useState<LastSelection>({
        products: [],
        projects: [],
        revisions: [],
        builds: [],
        networkBuilds: []
    });
    const [errorMessage, setErrorMessage] = useState("");

    const updateErrorMessage = (message: string) => {
        setErrorMessage(message);
    };

    const [hasExclusions, updateHasExclusions] = useState(false);
    const [
        fetchAnalyticsSchema,
        // @ts-ignore
        { data: { getAnalyticsSchema } = {} }
    ] = useLazyQuery<AnalyticsSchemaData, AnalyticsSchemaVariables>(
        GET_ANALYTICS_SCHEMA,
        {
            variables: {
                analyticsProducts: analyticsProducts.flatMap(
                    (product: Product) =>
                        product.selected ? Number(product.id) : []
                ),
                analyticsProjects: analyticsProjects.flatMap(
                    (project: Project) =>
                        project.selected ? Number(project.id) : []
                ),
                analyticsRevisions: analyticsRevisions.flatMap(
                    (revision: Revision) =>
                        revision.selected ? Number(revision.id) : []
                ),
                analyticsBuilds: analyticsBuilds.flatMap((build: BatchBuild) =>
                    build.selected ? Number(build.id) : []
                ),
                analyticsNetworkBuilds: analyticsNetworkBuilds.flatMap(
                    (networkBuild: NetworkBuild) =>
                        networkBuild.selected ? Number(networkBuild.id) : []
                ),
                apiVersion: apiVersion
            },
            fetchPolicy: "network-only"
        }
    );

    useEffect(() => {
        updateErrorMessage("");
    }, [analyticsState]);

    useEffect(() => {
        const newState = {
            products: analyticsProducts,
            projects: analyticsProjects,
            revisions: analyticsRevisions,
            builds: analyticsBuilds,
            networkBuilds: analyticsNetworkBuilds
        };
        if (JSON.stringify(currentSelection) !== JSON.stringify(newState)) {
            setCurrentSelection(newState);
            fetchAnalyticsSchema();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        analyticsNetworkBuilds,
        analyticsBuilds,
        analyticsRevisions,
        analyticsProjects,
        analyticsProducts
    ]);

    const checkExclusions = (elements: FunnelData[]) => {
        updateHasExclusions(elements.some(element => element.exclude));
    };

    const updateFunnelEvent = (id: number, funnelItem: FunnelData) => {
        const newFunnelEvents = [...funnelEvents];
        newFunnelEvents[id] = funnelItem;
        checkExclusions(newFunnelEvents);
        updateFunnelItems(client, newFunnelEvents);
    };

    const removeFunnelEvent = (id: number) => {
        if (funnelEvents.length <= 2) {
            console.log("[DEBUG] Funnel requires 2 items");
            return;
        }
        const newFunnelEvents = [
            ...funnelEvents.slice(0, id),
            ...funnelEvents.slice(id + 1)
        ];
        checkExclusions(newFunnelEvents);
        updateFunnelItems(client, newFunnelEvents);
    };

    const dragItem = React.useRef<number | null>(null);
    const dragOverItem = React.useRef<number | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleDragStart = (
        event:
            | React.DragEvent<HTMLDivElement>
            | React.TouchEvent<HTMLDivElement>,
        index: number
    ) => {
        event.stopPropagation();
        dragItem.current = index;
        setIsDragging(true);
        if ("dataTransfer" in event) {
            const ghostElement = document.createElement("div");
            event.dataTransfer.setDragImage(ghostElement, 0, 0);
        }
    };

    const handleDragEnter = (index: number) => {
        dragOverItem.current = index;
        if (
            dragItem.current !== null &&
            dragItem.current !== dragOverItem.current
        ) {
            const newFunnelEvents = [...funnelEvents];
            const [draggedItem] = newFunnelEvents.splice(dragItem.current, 1);
            newFunnelEvents.splice(dragOverItem.current, 0, draggedItem);

            dragItem.current = dragOverItem.current;
            dragOverItem.current = null;

            updateFunnelItems(client, newFunnelEvents);
            checkExclusions(newFunnelEvents);
        }
    };

    const handleDrop = () => {
        setIsDragging(false);
        dragItem.current = null;
        dragOverItem.current = null;
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        dragItem.current = null;
        dragOverItem.current = null;
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        const touch = event.touches[0];
        const elementsAtPoint = document.elementsFromPoint(
            touch.clientX,
            touch.clientY
        );
        const draggableElement = elementsAtPoint.find(element =>
            element.classList.contains("draggable-item")
        );

        if (draggableElement) {
            const index = parseInt(
                draggableElement.getAttribute("data-index") || "-1",
                10
            );
            if (index !== -1) {
                handleDragEnter(index);
            }
        }
    };

    const funnelElements = funnelEvents.map((element, index) => (
        <div
            key={index}
            data-index={index}
            className={cx("draggable-item mouse-grab", {
                "dragging-element": isDragging && dragItem.current === index,
                "reduced-opacity": isDragging && dragItem.current !== index
            })}
            draggable
            onDragStart={(element: React.DragEvent<HTMLDivElement>) =>
                handleDragStart(element, index)
            }
            onTouchStart={(event: React.TouchEvent<HTMLDivElement>) =>
                handleDragStart(event, index)
            }
            onDragEnter={() => handleDragEnter(index)}
            onTouchMove={handleTouchMove}
            style={{ touchAction: "none" }}
            onDragEnd={handleDragEnd}
            onTouchEnd={handleDragEnd}
            onDrop={handleDrop}
            onDragOver={element => element.preventDefault()}
        >
            <EditableFunnelComponent
                id={index}
                isRemovable={funnelEvents.length > 2}
                funnelItem={element}
                analyticsSchema={
                    getAnalyticsSchema?.schema
                        ? JSON.parse(JSON.stringify(getAnalyticsSchema.schema))
                        : undefined
                }
                updateItem={updateFunnelEvent}
                removeText={removeFunnelEvent}
            />
        </div>
    ));

    if (
        queryType !== AnalyticsQueryType.Funnel &&
        queryType !== AnalyticsQueryType.DaySelectionFunnel
    ) {
        return null;
    }

    if (!getAnalyticsSchema?.success) {
        return (
            <Card style={{ minHeight: "280px" }} className="mb-2">
                <Card.Header>
                    <h5 className="text-inverse-75">Funnel</h5>
                </Card.Header>
                <Card.Body>
                    <p>
                        <strong className="text-inverse-75">
                            No supported schema found for the selection
                        </strong>
                    </p>
                    <p className="text-inverse-50">
                        This is most likely due to mismatching schemas between
                        selected builds and/or misconfiguration in build(s)
                        analytics schema field.
                    </p>
                    <p className="text-inverse-50 analytics-text">
                        Try changing the set of items in Query Selection.
                    </p>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card style={{ minHeight: "280px" }} className="mb-2">
            <Card.Header className="text-inverse-50 d-flex">
                <h5 className="text-inverse-75">Funnel Events</h5>
            </Card.Header>
            <Card.Body>
                {funnelElements}
                <div className="d-flex align-items-middle pt-3 px-4">
                    <Button
                        onClick={(event: SyntheticEvent) => {
                            event.stopPropagation();
                            updateFunnelItems(client, [
                                ...funnelEvents,
                                {
                                    eventName: "",
                                    eventData: []
                                }
                            ]);
                        }}
                        className="btn-sm"
                        title="Add Event"
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" />
                    </Button>
                    <span className="text-inverse-50 ms-3 mt-1">
                        Add Event...
                    </span>
                </div>
                <div className="text-end">
                    <AnalyticsQueryButtonWrapper
                        queryType={queryType}
                        setErrorMessage={updateErrorMessage}
                    />
                    {errorMessage && (
                        <div className="text-danger pt-1 bread-text">
                            {errorMessage}
                        </div>
                    )}
                </div>
            </Card.Body>
            {hasExclusions ||
                (!getAnalyticsSchema?.isComplete && (
                    <Card.Footer className="text-inverse-50 bread-text pb-0">
                        {hasExclusions ? (
                            <p>
                                Please note, that currently exclusions only
                                apply to funnel queries (first bar/line chart).
                            </p>
                        ) : null}
                        {!getAnalyticsSchema?.isComplete ? (
                            <p>
                                Select Event -dropdown list is incomplete due
                                partly mismatching schemas.
                            </p>
                        ) : null}
                    </Card.Footer>
                ))}
        </Card>
    );
};
