import {
    AnalyticsLoadingState,
    AnalyticsQueryTask,
    AnalyticsTimestamps,
    AthenaDatasetResult,
    AthenaQueryResult,
    BatchBuild,
    BuildJobData,
    BuildNotification,
    FunnelData,
    FunnelTimestamps,
    NetworkBuild,
    Product,
    Project,
    Revision,
    SortingMode,
    ToastData
} from "./types";

export type State = {
    initialized: boolean;
    buildNotifications: BuildNotification[];
    developerMode: boolean;
    isDeveloper: boolean;
    showArchived: boolean;
    useDarkMode: boolean;
    redirect: string | undefined;
    productTier: Set<number>;
    projectTier: Set<number>;
    searchTerm?: string;
    id: string;
    __typename: string;
};

export type AnalyticsState = {
    analyticsEnabled: boolean;
    analyticsSelectedTab: string;
    funnelApiVersion: string;
    analyticsFunnelSelectionMode: boolean;
    analyticsProducts: Product[];
    analyticsProjects: Project[];
    analyticsRevisions: Revision[];
    analyticsBuilds: BatchBuild[];
    analyticsNetworkBuilds: NetworkBuild[];
    funnelEvents: FunnelData[];
    analyticsTimestamps: AnalyticsTimestamps;
    funnelTimestamps: FunnelTimestamps;
    analyticsTask: AnalyticsQueryTask | undefined;
    analyticsLoadingState: AnalyticsLoadingState;
    allCtrAnalyticsResults: AthenaQueryResult[];
    funnelAnalyticsResults: AthenaDatasetResult[];
    includeBuildPreview: boolean;
    includeBuildComparison: boolean;
    includeDayFunnel: boolean;
    clearCache: boolean;
    id: string;
    __typename: string;
};

export type BuildsState = {
    buildJobsData?: BuildJobData[] | undefined;
    id: string;
    __typename: string;
};

export type NotificationState = {
    showLoading: boolean;
    showToast: boolean;
    toastData?: ToastData;
    id: string;
    __typename: string;
};

export interface SortingStateData {
    sortingState: SortingState;
}

export interface SortingState {
    sortingPriorities: SortingMode[];
    organizationSorting: boolean;
    id: string;
    __typename: string;
}

export type PaginatorState = {
    currentPage: number;
    id: string;
    __typename: string;
};

export type EditPanelState = {
    legacyLocalizationCheck: boolean;
    legacyLocalizationErrorString: string;
    pathTemplateCheck: boolean;
    mutatorConfigCheck: boolean;
    defaultLocalizationMissing: boolean;
    id: string;
    __typename: string;
};

export interface MutatorStateData {
    mutatorState: MutatorState;
}

export type MutatorState = {
    showMeta: boolean;
    id: string;
    __typename: string;
};

export enum VariationCollapseState {
    Builds,
    BuildDetails,
    Variations,
    VariationDetails,
    LegacyLocalizations,
    Localizations,
    Networks,
    Reskins,
    Placeholders
}

export enum VariationViewType {
    Builds = "builds",
    Variations = "variations",
    Localizations = "localizations",
    LegacyLocalizations = "legacyLocalizations",
    Networks = "networks",
    Settings = "settings",
    Results = "results",
    Versions = "versions",
    Variables = "variables"
}

export interface VariationViewItemProps {
    revision: Revision;
    developerMode: boolean;
    shouldBeVisible?: boolean;
}

export interface VariationItemProps {
    revision?: Revision;
    callback?: (item: any) => void;
}

export enum TechStackType {
    Frameworks,
    Sounds,
    Particles,
    Physics,
    Animations,
    Networks
}

export enum SearchFilterType {
    None,
    Products,
    Projects,
    Revisions
}
