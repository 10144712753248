import React, { FC, ReactElement, useEffect, useState } from "react";
import { loadCsv } from "../../common/AnalyticsHelpers";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { DSVRowArray } from "d3";
import { Card, Col, Spinner, Tab, Tabs } from "react-bootstrap";
import { ScatterChart } from "./ScatterChart";
import { useLocalState } from "../../graphql/hooks";
import { OverlayBadge } from "../simple/OverlayBadge";

interface Props {
    data: AthenaDatasetResult[];
}

const BothHeatmapsImplementation: FC<Props> = ({ data }) => {
    const { useDarkMode } = useLocalState();
    const [isLoading, setIsLoading] = useState(false);
    const [heatmapPortraitCsvData, updateHeatmapPortraitCsvData] = useState<
        DSVRowArray[]
    >([]);
    const [heatmapLandscapeCsvData, updateHeatmapLandscapeCsvData] = useState<
        DSVRowArray[]
    >([]);

    useEffect(() => {
        setIsLoading(true);
        const fetchCsvData = async () => {
            const csvDatasPortrait: DSVRowArray[] = [];
            const csvDatasLandscape: DSVRowArray[] = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                for (let j = 0; j < element.dataset.length; j++) {
                    const dataset = element.dataset[j];
                    if (dataset.success && dataset.message) {
                        if (
                            dataset.type === AnalyticsQueryType.HeatmapPortrait
                        ) {
                            csvDatasPortrait.push(
                                await loadCsv(dataset.message)
                            );
                        } else if (
                            dataset.type === AnalyticsQueryType.HeatmapLandscape
                        ) {
                            csvDatasLandscape.push(
                                await loadCsv(dataset.message)
                            );
                        }
                    }
                }
            }
            updateHeatmapPortraitCsvData(csvDatasPortrait);
            updateHeatmapLandscapeCsvData(csvDatasLandscape);
            setIsLoading(false);
        };
        fetchCsvData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <div className="text-center my-4 text-inverse-50">
                <span className="me-2">Loading Heatmaps...</span>
                <Spinner animation="border" role="status"></Spinner>
            </div>
        );
    }

    if (
        heatmapPortraitCsvData.length === 0 ||
        heatmapLandscapeCsvData.length === 0
    ) {
        return null;
    }

    const getChartPortrait = (index: number) => {
        return (
            <div
                style={{ height: "528px" }}
                className="mt-3"
                key={"portrait" + index}
            >
                <ScatterChart
                    data={heatmapPortraitCsvData[index]}
                    darkMode={useDarkMode}
                    showLegend={true}
                    aspectRatio={0.75}
                    title="Portrait"
                />
            </div>
        );
    };

    const getChartLandscape = (index: number) => {
        return (
            <div
                style={{ height: "294px" }}
                className="mt-3"
                key={"landscape" + index}
            >
                <ScatterChart
                    data={heatmapLandscapeCsvData[index]}
                    darkMode={useDarkMode}
                    showLegend={true}
                    aspectRatio={1.6}
                    title="Landscape"
                />
            </div>
        );
    };

    const elements: ReactElement[] = [];
    const showName = data.length > 1;

    for (let i = 0; i < data.length; i++) {
        elements.push(
            <Col key={i}>
                <Card className="text-inverse mt-4">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <h5>
                            <span className="text-inverse-75 ">Heatmaps</span>
                            {showName ? " " + data[i].datasetName : ""}
                        </h5>
                        <OverlayBadge
                            badgeHeader="?"
                            badgeVariant="secondary"
                            className="float-end ms-3"
                            overlayText="Heatmaps currently show data points only from ctaBtnPressed event - Note that data is combined from all builds selected for the query!"
                        ></OverlayBadge>
                    </Card.Header>
                    <Card.Body>
                        <Tabs defaultActiveKey="portrait" id={`tabs-${i}`}>
                            <Tab eventKey="portrait" title="Portrait Data">
                                {getChartPortrait(i)}
                            </Tab>
                            <Tab eventKey="landscape" title="Landscape Data">
                                {getChartLandscape(i)}
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
    return <>{elements}</>;
};

export const BothHeatmaps = React.memo(BothHeatmapsImplementation);
