import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import {
    Badge,
    Button,
    Card,
    Collapse,
    FormControl,
    InputGroup
} from "react-bootstrap";
import {
    Project,
    ProjectConfigData,
    ProjectConfigInput,
    ProjectConfigVariables,
    SoftLimitsData,
    UpdateProjectConfigData
} from "../../../models/types";
import { cleanupCommaSeparatedString } from "../../../common/Helpers";
import { GET_PROJECT_CONFIG } from "../../../graphql/queries";
import { EditableJsonView } from "../../editable/EditableJsonView";
import { UPDATE_PROJECT_CONFIG } from "../../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";

import cx from "classnames";

const AllowedParams = ["product", "project", "revision", "build"];
const AllowedCharRegex = /^[a-zäöå0-9_-]+$/i;

interface Props {
    project: Project;
}

export const ProjectConfig: FC<Props> = ({ project }) => {
    const [show, toggleShow] = useState(false);
    const [showInfo, updateShowInfo] = useState(false);
    const [updating, updateUpdating] = useState(false);
    const [hasUnsaved, updateHasUnsaved] = useState(false);
    const [isValid, updateIsValid] = useState(true);
    const [validationMessage, updateValidationMessage] = useState<string>("");
    const [currentZipName, updateCurrentZipName] = useState<string | null>("");
    const [zipNamePreview, updateZipNamePreview] = useState<string | undefined>(
        undefined
    );
    const [currentNetworks, updateCurrentNetworks] = useState<string[] | null>(
        []
    );

    const { data: { projectConfig } = {} } = useQuery<ProjectConfigData>(
        GET_PROJECT_CONFIG,
        {
            variables: {
                projectId: project.id
            }
        }
    );
    const [projectConfigMutation] = useMutation<
        UpdateProjectConfigData,
        ProjectConfigVariables
    >(UPDATE_PROJECT_CONFIG);

    useEffect(() => {
        if (projectConfig && projectConfig.analyticsNetworks) {
            updateCurrentNetworks(projectConfig.analyticsNetworks);
        }
        if (projectConfig && projectConfig.zipNameTemplate) {
            updateCurrentZipName(projectConfig.zipNameTemplate);
        }
    }, [projectConfig]);

    useEffect(() => {
        const validZipNameTemplate = () => {
            if (!currentZipName) {
                updateIsValid(true);
            } else {
                const matches =
                    currentZipName.toLowerCase().match(/(?<={)\w+(?=})/g) || [];
                const validParams = matches.every(el =>
                    AllowedParams.includes(el)
                );
                if (!validParams) {
                    updateValidationMessage(
                        "Invalid parameter(s): " + AllowedParams
                    );
                    updateIsValid(false);
                } else {
                    const fileName = currentZipName
                        .toLowerCase()
                        .replace(/(#{\w+})/g, "-")
                        .replace(/({\w+})/g, "-");
                    const validFilename = AllowedCharRegex.test(fileName);
                    if (!validFilename) {
                        updateValidationMessage(
                            "Invalid characters: " + AllowedCharRegex
                        );
                        updateIsValid(false);
                    } else {
                        updateValidationMessage("");
                        updateIsValid(true);
                    }
                }
            }
        };

        const createZipNamePreview = () => {
            const namePreview = currentZipName
                ?.toLowerCase()
                .replace(/(#{\w+})/g, "1234")
                .replace(/[{}]/g, "");
            updateZipNamePreview(namePreview);
        };

        validZipNameTemplate();
        createZipNamePreview();
    }, [currentZipName]);

    const updateProjectConfig = async (newInput: ProjectConfigInput) => {
        try {
            await projectConfigMutation({
                variables: {
                    input: newInput
                },
                refetchQueries: [
                    {
                        query: GET_PROJECT_CONFIG,
                        variables: { projectId: project.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateProjectConfig error ", error);
        }
    };

    const updateSoftLimits = async (newLimits: SoftLimitsData | null) => {
        updateUpdating(true);
        const newInput = {
            projectId: project.id,
            softLimits: newLimits
        };

        await updateProjectConfig(newInput);
        updateUpdating(false);
        updateHasUnsaved(false);
    };

    const updateAnalyticsNetworks = async (newNetworks: string[] | null) => {
        if (newNetworks) {
            newNetworks = newNetworks?.filter(element => element !== "");
            updateCurrentNetworks(newNetworks);
        }
        if (
            projectConfig?.analyticsNetworks?.toString() ===
            newNetworks?.toString()
        )
            return;
        const newInput = {
            projectId: project.id,
            analyticsNetworks: newNetworks
        };

        await updateProjectConfig(newInput);
    };

    const updatezipNameTemplate = async (zipNameTemplate: string | null) => {
        if (projectConfig?.zipNameTemplate === zipNameTemplate) return;
        if (!isValid) {
            console.log("Invalid zip name template, not save to DB!!");
            return;
        }
        const newInput = {
            projectId: project.id,
            zipNameTemplate: zipNameTemplate
        };

        await updateProjectConfig(newInput);
    };

    return (
        <>
            <Card className="mt-2">
                <Card.Header>
                    Project Config
                    <FontAwesomeIcon
                        size="sm"
                        icon={show ? faChevronUp : faChevronDown}
                        className="mouseHover mx-2"
                        onClick={() => {
                            toggleShow(!show);
                        }}
                        title={show ? "Hide details" : "Show details"}
                    />
                    <div
                        className={cx("float-end ms-auto mouseHover", {
                            "text-inverse-50": showInfo,
                            "text-inverse-25": !showInfo
                        })}
                        onClick={() => updateShowInfo(!showInfo)}
                    >
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            title="Toggle help and additional info"
                        />
                    </div>
                    {hasUnsaved ? (
                        <div className="d-flex float-end ms-auto">
                            <Badge bg="warning">Changes Not Saved</Badge>
                        </div>
                    ) : null}
                </Card.Header>
                <Collapse in={show}>
                    <Card.Body>
                        <Card className="mb-3">
                            <Card.Header>Soft Limits</Card.Header>
                            <Card.Body>
                                {projectConfig === undefined ||
                                projectConfig?.softLimits === null ? (
                                    <Button
                                        onClick={() => {
                                            updateSoftLimits({ global: [] });
                                        }}
                                    >
                                        Create Soft Limits Config
                                    </Button>
                                ) : (
                                    <EditableJsonView
                                        jsonData={JSON.stringify(
                                            projectConfig.softLimits,
                                            null,
                                            2
                                        )}
                                        updateJsonData={updateSoftLimits}
                                        updating={updating}
                                        updateHasUnsavedData={updateHasUnsaved}
                                        removeJsonData={() =>
                                            updateSoftLimits(null)
                                        }
                                    />
                                )}
                                <Collapse in={showInfo}>
                                    <Card.Footer>
                                        Soft limits override the organization
                                        level soft limits if applicable. These
                                        can be overridden by revision level
                                        overrides. Remove this override to go
                                        back to defaults or organization level
                                        overrides if applicable.
                                    </Card.Footer>
                                </Collapse>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Header>Analytics Networks</Card.Header>
                            <Card.Body>
                                {projectConfig === undefined ||
                                projectConfig?.analyticsNetworks === null ? (
                                    <Button
                                        onClick={() => {
                                            updateAnalyticsNetworks([]);
                                        }}
                                    >
                                        Create Analytics Networks
                                    </Button>
                                ) : (
                                    <>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                Analytics Networks
                                            </InputGroup.Text>
                                            <FormControl
                                                value={currentNetworks?.join(
                                                    ","
                                                )}
                                                onChange={(event: any) => {
                                                    const newAnalyticsNetworks =
                                                        cleanupCommaSeparatedString(
                                                            event.target.value
                                                        );
                                                    updateCurrentNetworks(
                                                        newAnalyticsNetworks
                                                    );
                                                }}
                                                onBlur={() => {
                                                    updateAnalyticsNetworks(
                                                        currentNetworks
                                                    );
                                                }}
                                            />
                                            <Button
                                                variant="danger"
                                                onClick={() => {
                                                    updateAnalyticsNetworks(
                                                        null
                                                    );
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </InputGroup>
                                    </>
                                )}
                                <Collapse in={showInfo}>
                                    <Card.Footer>
                                        Analytics networks overrides default
                                        values for this project. You can
                                        temporarily enable and/or disable
                                        analytics networks for this project.
                                        Remove this override to go back to
                                        defaults.
                                    </Card.Footer>
                                </Collapse>
                            </Card.Body>
                        </Card>
                        <Card className={!isValid ? "border-danger" : ""}>
                            <Card.Header>Zip Name Template</Card.Header>
                            <Card.Body>
                                {!isValid ? (
                                    <p className="text-danger">
                                        Invalid zip name template:{" "}
                                        {validationMessage}
                                    </p>
                                ) : null}
                                {projectConfig === undefined ||
                                projectConfig?.zipNameTemplate === null ? (
                                    <Button
                                        onClick={() => {
                                            updatezipNameTemplate("");
                                        }}
                                    >
                                        Create Zip Name Template
                                    </Button>
                                ) : (
                                    <InputGroup>
                                        <InputGroup.Text>
                                            Zip Name Template
                                        </InputGroup.Text>
                                        <FormControl
                                            defaultValue={
                                                projectConfig?.zipNameTemplate
                                                    ? projectConfig.zipNameTemplate
                                                    : ""
                                            }
                                            onChange={(event: any) => {
                                                updateCurrentZipName(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={() => {
                                                updatezipNameTemplate(
                                                    currentZipName
                                                );
                                            }}
                                        />
                                        <Button
                                            variant="danger"
                                            onClick={() => {
                                                updatezipNameTemplate(null);
                                                updateCurrentZipName("");
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </InputGroup>
                                )}
                                <Collapse
                                    in={showInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="mt-1 mb-1 no-border text-inverse-50 bread-text">
                                            <span>
                                                Zip file names will be generated
                                                based on this customizable
                                                template tool. The template
                                                parameters can be modified. See
                                                the preview feature to check how
                                                your file name will turn out.
                                            </span>
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                <Collapse
                                    in={showInfo}
                                    className="col-12 m-0 p-0"
                                >
                                    <div>
                                        <Card.Footer className="mt-1 mb-0 no-border text-inverse-50 bread-text">
                                            <h6 className="text-inverse">
                                                Parameter Support
                                            </h6>
                                            <div>
                                                <ul>
                                                    <li>
                                                        {"#{parameter}"} -
                                                        parameter will be
                                                        modified, parameter can
                                                        only be product,
                                                        project, revision or
                                                        build
                                                    </li>
                                                    <li>
                                                        {"{project}"} will be
                                                        replaced by project's
                                                        name
                                                    </li>
                                                    <li>
                                                        {"#{project}"} will be
                                                        replaced by project's id
                                                    </li>
                                                    <li>
                                                        for example:{" "}
                                                        {
                                                            "#{product}_{project}_#{build}"
                                                        }{" "}
                                                        will become
                                                        1234_project-name_1234
                                                    </li>
                                                </ul>
                                            </div>
                                        </Card.Footer>
                                    </div>
                                </Collapse>
                                {zipNamePreview ? (
                                    <p
                                        className={cx(
                                            { "text-primary": isValid },
                                            { "text-danger": !isValid }
                                        )}
                                    >
                                        Name Template Preview: {zipNamePreview}
                                    </p>
                                ) : null}
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Collapse>
            </Card>
        </>
    );
};
