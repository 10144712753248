import React from "react";
import { Revision } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelRevision } from "../../simple/analytics/FunnelRevision";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./FunnelProductsDetails.module.scss";

const FunnelRevisionDetailsImplementation = () => {
    const { analyticsRevisions, analyticsProjects, analyticsProducts } =
        useLocalAnalyticsState();

    if (analyticsRevisions.length === 0) {
        return null;
    }

    const revisions = analyticsRevisions
        .map((revision: Revision) => {
            if (
                analyticsProjects.some(
                    project => revision.project?.id === project.id
                )
            ) {
                return null;
            }
            if (
                analyticsProducts.some(
                    product => revision.project?.product?.id === product.id
                )
            ) {
                return null;
            }
            return <FunnelRevision key={revision.id} revision={revision} />;
        })
        .filter(revision => revision !== null);

    return (
        <>
            {revisions.length > 0 && (
                <div className={`mb-3 ${styles.container}`}>
                    <Row className="d-flex align-items-center no-wrap mb-2">
                        <Col className={styles.title}>
                            <b className="text-inverse-75">
                                Selected Revisions
                            </b>
                        </Col>
                        <Col className={styles.angles}>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Resize window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {revisions}
                </div>
            )}
        </>
    );
};

export const FunnelRevisionDetails = React.memo(
    FunnelRevisionDetailsImplementation
);
