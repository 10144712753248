import { useQuery } from "@apollo/client";
import {
    GET_LOCAL_ANALYTICS_STATE,
    GET_LOCAL_BUILD_STATE,
    GET_LOCAL_EDITPANEL_STATE,
    GET_LOCAL_MUTATOR_STATE,
    GET_LOCAL_NOTIFICATION_STATE,
    GET_LOCAL_PAGINATOR_STATE,
    GET_LOCAL_SORTING_STATE,
    GET_LOCAL_STATE,
    GET_REVISION
} from "./queries";
import {
    initialEditPanelState,
    initialLocalAnalyticsState,
    initialLocalBuildsState,
    initialLocalState,
    initialMutatorState,
    initialNotificationState,
    initialPaginatorState,
    initialSortingState
} from "./client";
import {
    AnalyticsStateData,
    BuildsStateData,
    EditPanelStateData,
    NotificationStateData,
    PaginatorStateData,
    ProjectState,
    ReleaseType,
    Revision,
    RevisionData,
    RevisionVariables,
    StateData
} from "../models/types";
import {
    AnalyticsState,
    BuildsState,
    EditPanelState,
    MutatorState,
    MutatorStateData,
    NotificationState,
    PaginatorState,
    SortingState,
    SortingStateData,
    State
} from "../models/common";
import { getSorting } from "../common/Helpers";

const getRevision = (data?: RevisionData) => {
    if (data && data.revision) {
        const revision = { ...data.revision };
        if (typeof revision.buildData === "string") {
            revision.buildData = JSON.parse(revision.buildData);
        }
        return revision;
    }
    return {
        id: -1,
        project: {
            id: 0,
            name: "",
            slackChannel: "",
            gitProjectId: undefined,
            gitProjectName: "",
            gitProjectBranch: "",
            state: ProjectState.Request,
            revisions: [],
            dateUpdated: ""
        },
        name: "",
        releaseType: ReleaseType.Development,
        buildData: undefined,
        revisionConfig: undefined,
        archived: false,
        dateUpdated: ""
    };
};

export const useRevision = (revisionId: number): Revision => {
    const { data } = useQuery<RevisionData, RevisionVariables>(GET_REVISION, {
        variables: { revisionId: revisionId }
    });
    return getRevision(data);
};

export const useRevisionStandby = (revisionId: number): Revision => {
    const { data } = useQuery<RevisionData, RevisionVariables>(GET_REVISION, {
        variables: { revisionId: revisionId },
        nextFetchPolicy: "standby"
    });
    return getRevision(data);
};

export const useLocalState = (): State => {
    const { data, client } = useQuery<StateData>(GET_LOCAL_STATE, {
        fetchPolicy: "cache-only"
    });
    if (data && data.state) {
        return data.state;
    } else {
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: { state: initialLocalState }
        });
        return initialLocalState;
    }
};

export const useLocalAnalyticsState = (): AnalyticsState => {
    const { data, client } = useQuery<AnalyticsStateData>(
        GET_LOCAL_ANALYTICS_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );
    if (data && data.analyticsState) {
        return data.analyticsState;
    } else {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: { analyticsState: initialLocalAnalyticsState }
        });
        return initialLocalAnalyticsState;
    }
};

export const useLocalBuildsState = (): BuildsState => {
    const { data, client } = useQuery<BuildsStateData>(GET_LOCAL_BUILD_STATE, {
        fetchPolicy: "cache-only"
    });
    if (data && data.buildsState) {
        return data.buildsState;
    } else {
        client.writeQuery<BuildsStateData, BuildsStateData>({
            query: GET_LOCAL_BUILD_STATE,
            data: { buildsState: initialLocalBuildsState }
        });
        return initialLocalBuildsState;
    }
};

export const useLocalNotificationState = (): NotificationState => {
    const { data, client } = useQuery<NotificationStateData>(
        GET_LOCAL_NOTIFICATION_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );
    if (data && data.notificationState) {
        return data.notificationState;
    } else {
        client.writeQuery<NotificationStateData, NotificationStateData>({
            query: GET_LOCAL_NOTIFICATION_STATE,
            data: { notificationState: initialNotificationState }
        });
        return initialNotificationState;
    }
};

export const usePaginatorState = (): PaginatorState => {
    const { data, client } = useQuery<PaginatorStateData>(
        GET_LOCAL_PAGINATOR_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );
    if (data && data.paginatorState) {
        return data.paginatorState;
    } else {
        client.writeQuery<PaginatorStateData, PaginatorStateData>({
            query: GET_LOCAL_PAGINATOR_STATE,
            data: { paginatorState: initialPaginatorState }
        });
        return initialPaginatorState;
    }
};

export const useEditPanelState = (): EditPanelState => {
    const { data, client } = useQuery<EditPanelStateData>(
        GET_LOCAL_EDITPANEL_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );
    if (data && data.editPanelState) {
        return data.editPanelState;
    } else {
        client.writeQuery<EditPanelStateData, EditPanelStateData>({
            query: GET_LOCAL_EDITPANEL_STATE,
            data: { editPanelState: initialEditPanelState }
        });
        return initialEditPanelState;
    }
};

export const useSortingState = (): SortingState => {
    const { data, client } = useQuery<SortingStateData>(
        GET_LOCAL_SORTING_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );

    if (data && data.sortingState) {
        return data.sortingState;
    } else {
        const lastSortingState = getSorting();
        const sortingState = lastSortingState
            ? {
                  ...initialSortingState,
                  sortingPriorities: lastSortingState.sortingPriorities,
                  organizationSorting: lastSortingState?.organizationSorting
              }
            : initialSortingState;

        client.writeQuery<SortingStateData, SortingStateData>({
            query: GET_LOCAL_SORTING_STATE,
            data: { sortingState: sortingState }
        });
        return sortingState;
    }
};

export const useMutatorState = (): MutatorState => {
    const { data, client } = useQuery<MutatorStateData>(
        GET_LOCAL_MUTATOR_STATE,
        {
            fetchPolicy: "cache-only"
        }
    );

    if (data && data.mutatorState) {
        return data.mutatorState;
    } else {
        client.writeQuery<MutatorStateData, MutatorStateData>({
            query: GET_LOCAL_MUTATOR_STATE,
            data: { mutatorState: initialMutatorState }
        });
        return initialMutatorState;
    }
};
