import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FunnelProductsDetails } from "./FunnelProductsDetails";
import { FunnelProjectDetails } from "./FunnelProjectDetails";
import { FunnelRevisionDetails } from "./FunnelRevisionDetails";
import { FunnelBuildDetails } from "./FunnelBuildDetails";
import { FunnelNetworkBuildDetails } from "./FunnelNetworkBuildDetails";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { hasExtraSelection } from "../../../common/AnalyticsHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faScaleBalanced
} from "@fortawesome/free-solid-svg-icons";

export const QuerySelection = () => {
    const analyticsState = useLocalAnalyticsState();
    const { hasOrphans, orphans } = hasExtraSelection(analyticsState);
    const [showOrphanWarning, setShowOrphanWarning] = useState(false);
    const {
        analyticsProducts,
        analyticsProjects,
        analyticsRevisions,
        analyticsBuilds,
        analyticsNetworkBuilds
    } = useLocalAnalyticsState();

    useEffect(() => {
        setShowOrphanWarning(hasOrphans);
    }, [hasOrphans]);

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex">
                <h5 className="text-inverse-75 d-flex align-items-center mouseHover">
                    Query Selection
                </h5>
            </Card.Header>
            <Card.Body
                style={{ height: "313px", overflow: "auto" }}
                className="text-inverse"
            >
                {analyticsProducts.length > 0 ||
                analyticsProjects.length > 0 ||
                analyticsRevisions.length > 0 ||
                analyticsBuilds.length > 0 ||
                analyticsNetworkBuilds.length > 0 ? (
                    <>
                        <FunnelProductsDetails />
                        <FunnelProjectDetails />
                        <FunnelRevisionDetails />
                        <FunnelBuildDetails />
                        <FunnelNetworkBuildDetails />
                    </>
                ) : (
                    <p className="p-2 text-inverse-50">Selection is empty.</p>
                )}
            </Card.Body>
            {showOrphanWarning && (
                <Card.Footer className="pb-3">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-warning"
                        size="1x"
                    />
                    <span className="bread-text text-inverse ms-2">
                        Some selected data is not included in the query because
                        an item is set to comparison mode (
                        <FontAwesomeIcon
                            icon={faScaleBalanced}
                            size="xs"
                            color="lightgreen"
                        />
                        ), and only the children of that item are queried.
                        Re-configure the comparison or ignore this message.
                    </span>
                </Card.Footer>
            )}
        </Card>
    );
};
