import React from "react";
import { Project } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FunnelProject } from "../../simple/analytics/FunnelProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./FunnelProductsDetails.module.scss";

const FunnelProjectDetailsImplementation = () => {
    const { analyticsProjects, analyticsProducts } = useLocalAnalyticsState();
    if (analyticsProjects.length === 0) {
        return null;
    }

    const projects = analyticsProjects
        .map((project: Project, index: number) => {
            if (
                analyticsProducts.some(
                    product => project.product?.id === product.id
                )
            ) {
                return null;
            }
            return <FunnelProject key={index} project={project} />;
        })
        .filter(project => project !== null);

    return (
        <>
            {projects.length > 0 && (
                <div className={`mb-3 ${styles.container}`}>
                    <Row className="d-flex align-items-center no-wrap mb-2">
                        <Col className={styles.title}>
                            <b className="text-inverse-75">Selected Projects</b>
                        </Col>
                        <Col className={styles.angles}>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="show-more">
                                        Resize window to show Analytics data
                                        columns
                                    </Tooltip>
                                }
                            >
                                <FontAwesomeIcon icon={faAnglesRight} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    {projects}
                </div>
            )}
        </>
    );
};

export const FunnelProjectDetails = React.memo(
    FunnelProjectDetailsImplementation
);
