import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { updateFunnelTimestamps } from "../../../common/AnalyticsHelpers";
import { getLastAnalyticsUpdateDate } from "../../../common/Helpers";
import styles from "../Dropdowns.module.scss";

export const AnalyticsTimeRangeDropdown = () => {
    const client = useApolloClient();
    const times = [
        { name: "Time Range", id: 0 },
        { name: "Last 24h", id: 1 },
        { name: "Last Week", id: 2 },
        { name: "Last Month", id: 3 },
        { name: "Last Year", id: 4 }
    ];
    const [selectedRange, setSelectedRange] = useState("Time Range");

    const updateDates = (id: number, name: string) => {
        const referenceTime = getLastAnalyticsUpdateDate();
        const nowTimestamp = Math.floor(referenceTime.getTime() / 1000);
        switch (id) {
            case 0:
                updateFunnelTimestamps(client, {
                    startTimestamp: -1,
                    endTimestamp: -1
                });
                setSelectedRange(name);
                break;
            case 1:
                const last24Hours = nowTimestamp - 24 * 3600;
                updateFunnelTimestamps(client, {
                    startTimestamp: last24Hours,
                    endTimestamp: nowTimestamp
                });
                setSelectedRange(name);
                break;
            case 2:
                const lastWeek = nowTimestamp - 24 * 3600 * 7;
                updateFunnelTimestamps(client, {
                    startTimestamp: lastWeek,
                    endTimestamp: nowTimestamp
                });
                setSelectedRange(name);
                break;
            case 3:
                const lastMonth = nowTimestamp - 24 * 3600 * 30;
                updateFunnelTimestamps(client, {
                    startTimestamp: lastMonth,
                    endTimestamp: nowTimestamp
                });
                setSelectedRange(name);
                break;
            case 4:
                // This "should" take into account leap day
                const currentDate = new Date();
                const dateForLastYear = new Date(currentDate);
                dateForLastYear.setFullYear(dateForLastYear.getFullYear() - 1);
                const lastYear = Math.floor(dateForLastYear.getTime() / 1000);
                updateFunnelTimestamps(client, {
                    startTimestamp: lastYear,
                    endTimestamp: nowTimestamp
                });
                setSelectedRange(name);
                break;
            default:
                break;
        }
    };

    const generateAlternatives = times?.map(
        (time: { name: string; id: number }) => (
            <Dropdown.Item
                key={time.id}
                eventKey={time.id.toString()}
                className={`text-inverse ${styles.dropdownItem}`}
            >
                {time.name}
            </Dropdown.Item>
        )
    );

    return (
        <DropdownButton
            id="select-time-dropdown"
            title={selectedRange}
            variant="primary"
            onSelect={(selectedItem: string | null) => {
                if (selectedItem) {
                    const selectedTime = times.find(
                        time => time.id.toString() === selectedItem
                    );
                    if (selectedTime) {
                        updateDates(selectedTime.id, selectedTime.name);
                    }
                }
            }}
        >
            {generateAlternatives}
        </DropdownButton>
    );
};
