import React, { FC, useState } from "react";
import { AnalyticsLoadingButtonWithProgress } from "./AnalyticsLoadingButtonWithProgress";
import { AnalyticsLoadingButton } from "./AnalyticsLoadingButton";
import { AnalyticsQueryButton } from "./AnalyticsQueryButton";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import {
    AnalyticsLoadingState,
    AnalyticsQueryConfig,
    AnalyticsQueryTask,
    AnalyticsQueryTaskFetchState,
    AnalyticsQueryType,
    AnalyticsQueryVariables,
    AnalyticsTaskResult,
    PlatformConfigData
} from "../../../models/types";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { GET_PLATFORM_CONFIG } from "../../../graphql/queries";
import {
    generateQuerySets,
    resetAnalyticsResults,
    updateAthenaTask,
    updateLoadingState
} from "../../../common/AnalyticsHelpers";
import { GENERATE_ANALYTICS_TASK } from "../../../graphql/mutations";

interface Props {
    queryType: AnalyticsQueryType;
    setErrorMessage: (message: string) => void;
}

export const AnalyticsQueryButtonWrapper: FC<Props> = ({
    queryType,
    setErrorMessage
}) => {
    const client = useApolloClient();
    const analyticsState = useLocalAnalyticsState();
    const { analyticsTask } = useLocalAnalyticsState();
    const [taskLoading, updateTaskLoading] = useState(false);
    const updateErrorMessage = (message: string) => {
        setErrorMessage(message);
    };
    const { data: { getPlatformConfig } = {} } =
        useQuery<PlatformConfigData>(GET_PLATFORM_CONFIG);

    const [getAnalyticsTask] = useMutation<
        AnalyticsTaskResult,
        AnalyticsQueryVariables
    >(GENERATE_ANALYTICS_TASK);

    const generateAthenaQuery = async (config: AnalyticsQueryConfig) => {
        try {
            const result = await getAnalyticsTask({
                variables: {
                    input: config
                }
            });

            if (result.data?.generateAnalyticsTask.taskId) {
                const subQueries = result.data?.generateAnalyticsTask.subTasks
                    ? result.data?.generateAnalyticsTask.subTasks
                    : undefined;
                const task: AnalyticsQueryTask = {
                    taskId: result.data?.generateAnalyticsTask.taskId,
                    type: AnalyticsQueryType.Funnel,
                    isReady: false,
                    includeDayFunnel: analyticsState.includeDayFunnel,
                    fetchStatus: AnalyticsQueryTaskFetchState.Querying,
                    subQueries: subQueries
                };
                return task;
            }
            return undefined;
        } catch (error) {
            console.log("[DEBUG] generateAthenaQuery error ", error);
        }
    };

    const taskQuery = async () => {
        updateTaskLoading(true);

        let hasEmptyEventData = false;
        loop1: for (let i = 0; i < analyticsState.funnelEvents.length; i++) {
            if (analyticsState.funnelEvents[i].eventData) {
                for (
                    let j = 0;
                    j < analyticsState.funnelEvents[i].eventData.length;
                    j++
                ) {
                    if (
                        !analyticsState.funnelEvents[i].eventData[j].key ||
                        !analyticsState.funnelEvents[i].eventData[j].value
                    ) {
                        hasEmptyEventData = true;
                        break loop1;
                    }
                }
            }
        }

        if (
            getPlatformConfig === undefined ||
            ((queryType === AnalyticsQueryType.DaySelectionFunnel ||
                queryType === AnalyticsQueryType.Funnel) &&
                (analyticsState.funnelEvents.length <= 0 ||
                    analyticsState.funnelEvents.some(item => !item.eventName) ||
                    hasEmptyEventData))
        ) {
            updateErrorMessage(
                "Event selection and/or event data is incomplete."
            );
            updateTaskLoading(false);
            return;
        }
        let analyticsTask: AnalyticsQueryTask | undefined = undefined;
        const config: AnalyticsQueryConfig = {
            apiVersion: getPlatformConfig.analyticsApiVersion,
            eventList: analyticsState.funnelEvents,
            dates: analyticsState.funnelTimestamps,
            includeDayFunnel: analyticsState.includeDayFunnel,
            includeBuildPreview: analyticsState.includeBuildPreview,
            includeBuildComparison: analyticsState.includeBuildComparison,
            querySets: generateQuerySets(analyticsState)
        };

        analyticsTask = await generateAthenaQuery(config);
        if (analyticsTask) {
            resetAnalyticsResults(client, AnalyticsQueryType.Funnel);
            updateAthenaTask(client, analyticsTask);
            updateLoadingState(client, AnalyticsLoadingState.QueryingAthena);
            updateErrorMessage("");
        } else {
            console.log("[DEBUG] Failed to generate athena query");
            updateErrorMessage("Failed to start the analytics query.");
        }
        updateTaskLoading(false);
    };

    if (analyticsTask !== undefined) {
        if (analyticsTask.type === AnalyticsQueryType.Funnel) {
            return <AnalyticsLoadingButtonWithProgress />;
        } else {
            return <AnalyticsLoadingButton title="Querying..." />;
        }
    }
    return (
        <>
            {taskLoading ? (
                <AnalyticsLoadingButton title="Starting..." />
            ) : (
                <AnalyticsQueryButton onQuery={taskQuery} />
            )}
        </>
    );
};
