import React, { useState } from "react";
import { NetworkBuild } from "../../../models/types";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { QueryDetailsNetworkBuildModal } from "../../modals/QueryDetailsNetworkBuildModal";
import { AnalyticsFunnelViewSelectionToggle } from "../../buttons/analytics/AnalyticsFunnelViewSelectionToggle";
import { DailyNetworkBuildAnalytics } from "../../simple/analytics/DailyNetworkBuildAnalytics";
import { UserQueryNetworkBuildAnalytics } from "../../simple/analytics/UserQueryNetworkBuildAnalytics";
import { AnalyticsFunnelViewComparisonToggle } from "../../buttons/analytics/AnalyticsFunnelViewComparisonToggle";
import styles from "./FunnelNetworkBuildDetails.module.scss";

const FunnelNetworkBuildDetailsImplementation = () => {
    const {
        analyticsNetworkBuilds,
        analyticsBuilds,
        analyticsRevisions,
        analyticsProjects,
        analyticsProducts
    } = useLocalAnalyticsState();
    const [selectedItem, setSelectedItem] = useState<NetworkBuild | undefined>(
        undefined
    );
    const [showModal, setShowModal] = useState(false);

    const handleShow = (item: NetworkBuild) => {
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleClose = () => {
        setSelectedItem(undefined);
        setShowModal(false);
    };

    if (analyticsNetworkBuilds.length === 0) {
        return null;
    }

    const networkBuilds = analyticsNetworkBuilds
        .map((networkBuild: NetworkBuild) => {
            if (
                analyticsBuilds.some(
                    build => networkBuild.build?.id === build.id
                )
            ) {
                return null;
            }
            if (
                analyticsRevisions.some(
                    revision => networkBuild.build?.revision.id === revision.id
                )
            ) {
                return null;
            }
            if (
                analyticsProjects.some(
                    project =>
                        networkBuild.build?.revision.project.id === project.id
                )
            ) {
                return null;
            }
            if (
                analyticsProducts.some(
                    product =>
                        networkBuild.build?.revision.project.product?.id ===
                        product.id
                )
            ) {
                return null;
            }
            return (
                <div className={styles.container} key={networkBuild.id}>
                    <Row className="p-1 align-items-center g-0">
                        <Col
                            className={`${styles.selectionToggleCol} d-flex align-items-center`}
                        >
                            <AnalyticsFunnelViewSelectionToggle
                                className="mx-1"
                                reference="networkbuild"
                                networkBuild={networkBuild}
                            />
                            <span
                                className="analytics-font ps-2"
                                title={`Filename: ${networkBuild.filename}`}
                            >
                                {networkBuild.buildName}
                            </span>
                            <AnalyticsFunnelViewComparisonToggle
                                reference="networkbuild"
                                className="ms-2"
                                networkBuild={networkBuild}
                            />
                        </Col>
                        <Col className={styles.analyticsCol}>
                            <DailyNetworkBuildAnalytics id={networkBuild.id} />
                        </Col>
                        <Col className={styles.analyticsCol}>
                            <UserQueryNetworkBuildAnalytics
                                id={networkBuild.id}
                            />
                        </Col>
                        <Col className={styles.infoIconCol}>
                            <FontAwesomeIcon
                                className="mouseHover ps-2 text-inverse-25"
                                icon={faInfoCircle}
                                onClick={() => handleShow(networkBuild)}
                            />
                        </Col>
                    </Row>
                </div>
            );
        })
        .filter(networkBuild => networkBuild !== null);

    return (
        <>
            <div className={styles.container}>
                <Row className="mb-2 no-wrap">
                    {networkBuilds.length > 0 && (
                        <>
                            <Col className={styles.title}>
                                <b className="text-inverse-75">
                                    Selected Network Builds
                                </b>
                            </Col>
                            <Col className={styles.angles}>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="show-more">
                                            Resize window to show Analytics data
                                            columns
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon
                                        className="me-2"
                                        icon={faAnglesRight}
                                    />
                                </OverlayTrigger>
                            </Col>
                        </>
                    )}
                </Row>
                {networkBuilds}
            </div>
            {selectedItem ? (
                <QueryDetailsNetworkBuildModal
                    networkBuild={selectedItem}
                    showModal={showModal}
                    hideModal={handleClose}
                />
            ) : null}
        </>
    );
};

export const FunnelNetworkBuildDetails = React.memo(
    FunnelNetworkBuildDetailsImplementation
);
