import React, { FC } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useApolloClient, useQuery } from "@apollo/client";
import {
    AnalyticsBatchBuildQueryData,
    AnalyticsBatchBuildQueryVariables,
    BatchBuildData,
    BatchBuildVariables,
    NetworkBuild
} from "../../models/types";
import {
    GET_BATCH_BUILD_BY_ANALYTICS_BUILD,
    GET_LOCAL_ANALYTICS_STATE,
    GET_RESULT
} from "../../graphql/queries";
import { useNavigate } from "react-router-dom";
import { SimpleDate } from "../simple/SimpleDate";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import { updateAnalyticsState } from "../../common/AnalyticsHelpers";

interface Props {
    showModal: boolean;
    networkBuild: NetworkBuild;
    hideModal: () => void;
}

export const QueryDetailsNetworkBuildModal: FC<Props> = ({
    showModal,
    networkBuild,
    hideModal
}) => {
    const analyticsState = useLocalAnalyticsState();
    const client = useApolloClient();
    const navigate = useNavigate();
    const { useDarkMode } = useLocalState();
    const { data } = useQuery<
        AnalyticsBatchBuildQueryData,
        AnalyticsBatchBuildQueryVariables
    >(GET_BATCH_BUILD_BY_ANALYTICS_BUILD, {
        variables: {
            buildId: networkBuild.analyticsBuildId
        }
    });

    const { data: { build } = {} } = useQuery<
        BatchBuildData,
        BatchBuildVariables
    >(GET_RESULT, {
        variables: {
            id: Number(data?.analyticsEnabledBuild.id)
        }
    });
    const url = `/products/${build?.revision.project.product?.id}/projects/${build?.revision.project.id}/revisions/${build?.revision.id}/history/${build?.id}/analyticsSchema`;

    return (
        <Modal
            size="xl"
            show={showModal}
            onHide={hideModal}
            backdrop="static"
            className={useDarkMode ? "modal-dark" : ""}
        >
            <Modal.Header closeButton>
                <h5>
                    Build {build?.id} of {build?.revision.name}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <Card.Body className="p-2">
                    <div className="bread-text px-2 mb-3">
                        <h6>Selected Network Build</h6>
                        {networkBuild.filename}
                    </div>
                    {build && build.analyticsSchema ? (
                        <div className="bread-text px-2">
                            <h6>Schema description</h6>{" "}
                            {build.analyticsSchema?.description ||
                                "No description for schema found."}
                        </div>
                    ) : null}
                    <div className="float-start me-auto p-2 bread-text">
                        <p>
                            Build created:{" "}
                            {build ? (
                                <SimpleDate dateUpdated={build.dateCreated} />
                            ) : null}
                        </p>
                    </div>
                </Card.Body>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button
                    onClick={() => {
                        updateAnalyticsState(client, {
                            ...analyticsState,
                            clearCache: false
                        });
                        navigate(url);
                    }}
                    title="Go to Schema editor - this does not reset funnel selection"
                    variant="primary"
                    className="me-2"
                >
                    Go to Schema Editor
                </Button>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
